<template>
  <div class="dairy-wrapper">
    <p-header></p-header>
    <div class="dairy-container">
      <img src="~common/images/case_top.png" width="100%" alt="">
      <div class="dairy-content-box">
        <div class="dairy-item-box">
          <div class="dairy-item" @click="goDetail(1)">
            <p class="dairy-item-title">促销引流两不误，传统营销玩法如何重回第一梯队帮助品牌快速动销？</p>
            <p class="dairy-item-text">2021年，国内快消品市场终于挨过了黑天鹅风波。消费者重新回归线下门店，如何在这段市场复苏快速上升的时期抓住机会，快速提升销量抢占终端铺市率？某乳酸菌饮品通过结合一物一码技术，打造“揭盖有奖 壹元换购活动”，给出了答案。</p>
            <a href="javascript:void(0)" class="dairy-item-btn">查看详情></a>
          </div>
          <div class="dairy-item" @click="goDetail(2)">
            <p class="dairy-item-title">某鲜奶品牌节日促销做对了哪些事？</p>
            <p class="dairy-item-text">2020年，国内市场饱受冲击。下半年形势稍缓，正值中秋、国庆双节的来临，快消品品牌都想借助这个关键时间点打一场漂亮的翻身仗。就在这个时间节点上，借助数码通独家核销系统，某鲜奶品牌上线了“迎中秋，贺国庆！喝XX鲜语，开瓶盖赢再来一瓶”活动。</p>
            <a href="javascript:void(0)" class="dairy-item-btn">查看详情></a>
          </div>
          <div class="dairy-item" @click="goDetail(3)">
            <p class="dairy-item-title">品牌营销拒绝内卷，看啤酒品牌如何“一举多得”</p>
            <p class="dairy-item-text">自2014年某饮用水品牌率先启用“一物一码”技术，将二维码应用到营销领域至今，通过产品包装赋码进行 “营销数字化 ”，已经被行业讨论了8年。这一路走来，二维码营销的玩法花样百出、各有千秋，涌现了无数另人拍案叫绝的惊艳案例。</p>
            <a href="javascript:void(0)" class="dairy-item-btn">查看详情></a>
          </div>
        </div>
      </div>
    </div>
    <p-footer></p-footer>
  </div>
</template>

<script type="text/ecmascript-6">
  import PHeader from 'components/header/header'
  import PFooter from 'components/footer/footer'
  import asideBar from 'components/asideBar/asideBar'

  export default {
    data() {
      return {}
    },
    created() {
    },
    activated() {
      document.title = '乳品行业 _ 北京数码通科技有限公司 _ 一物一码数字营销服务商，消费者互动社群运营商'
    },
    methods: {
      goDetail(id) {
        this.$router.push({
          name: 'caseDetail', query: {
            id: id
          }
        })
      }
    },
    watch: {},
    components: {
      PHeader,
      PFooter,
      asideBar
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .dairy-wrapper
    width 100%
    min-width 1270px
    .dairy-container
      width 100%
      box-sizing border-box
      .dairy-content-box
        padding .47rem 0 .5rem
        width 7.5rem
        box-sizing border-box
        color #333
        margin 0 auto
        .dairy-item-box
          display flex
          justify-content: center;
          flex-wrap wrap
          .dairy-item
            position relative
            width: 5.64rem;
            height: 1.27rem;
            margin-bottom: 0.06rem
            background: #FFFFFF;
            box-shadow: 0rem 0rem 0.05rem 0.05rem rgba(153, 153, 153, 0.2);
            border-radius: 0.07rem;
            cursor pointer
            &:hover
              border-color #D22828
          .dairy-item-title
            margin-top .36rem
            margin-left: 0.31rem
            font-size: 0.1rem;
            font-weight: bold;
            color: #333333;
            line-height: 0.15rem;
            letter-spacing: 1px;
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
          .dairy-item-text
            margin-top .1rem
            margin-left: 0.31rem
            margin-right: 0.31rem
            font-size: 0.07rem;
            font-weight: 300;
            color: #333333;
            line-height: 0.1rem;
          .dairy-item-btn
            display inline-block
            font-size: 0.08rem;
            font-weight: bold;
            color: #2356F7;
            position absolute
            bottom 0.1rem
            right 0.1rem
            line-height: 0.11rem;
</style>